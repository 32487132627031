import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    h1: {
      fontSize: '22px',
      fontWeight: 500,
      color: 'rgba(21, 14, 55, 0.85)',
    },
    h2: {
      fontSize: '16px',
      fontWeight: 400,
      color: 'rgba(21, 14, 55, 0.85)',
    },
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 500,
          height: '32px',
          padding: '0px 12px',
          borderRadius: '999px',
          textTransform: 'none',
          fontFamily: 'inherit',
          margin: '4px'
        }
      }
    },
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: '16px',
    //       fontWeight: 500,
    //       backgroundColor: '#6685FF',
    //       borderRadius: '999px',
    //       alignSelf: 'flex-end',
    //       textTransform: 'none',
    //     }
    //   }
    // }
  }
});
