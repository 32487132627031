import {
  Autocomplete,
  Box,
  Grid, Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { hideView, selectUiState, setAsyncState, setClickListener, showInfoView, showView } from '../../app/globalSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import AppDialog, { AppBox, AppButton, AppStackDivider } from '../../components/AppComponents';
import { AddressType, ApiServiceType } from '../../types';
import { MapViewType, selectMapView, setView, views } from '../map/mapSlice';
import { ExclusivePreferenceButtons, PreferenceButtons } from './PreferenceButtons';
import { fetchRoute, reverseGeocode, selectRoute, setDestinationAddress, setSourceAddress } from './routeSlice';

type SearchProps = {
  placeholder: string,
  valueName: 'sourceAddress' | 'destinationAddress',
  apiService: ApiServiceType,
  icon: string,
  value?: AddressType,
  // eslint-disable-next-line
  setSelected: (e: any | null) => void
}

const isMobileDevice = /Android|iPhone/i.test(navigator.userAgent)

const LayerItem = ({ name, label, infoContent }: MapViewType) => {

  const dispatch = useAppDispatch()
  const selected = useAppSelector(selectMapView).currentView.name === name
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid item xs={10}>
        <Typography
          sx={{
            cursor: 'pointer',
            display: 'inline',
            float: 'right',
          }}
          onClick={() => {
            dispatch(setView(name))
            dispatch(hideView('layerMenu'))
          }}
        >
          {t(label)}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        {selected && <span className="material-icons-outlined">done</span>}
      </Grid>
      {
        infoContent &&
        <Grid item xs={1}>
          <span onClick={() => dispatch(showInfoView({ content: infoContent, title: label }))} style={{ cursor: 'pointer', float: 'right' }} className="material-icons-outlined">info</span>
        </Grid>
      }
    </Grid>
  )
}

export const LayerMenu = () => {

  const dispatch = useAppDispatch()
  const { layerMenu: {visible} } = useAppSelector(selectUiState)

  return (
    <AppDialog open={visible} onClose={() => dispatch(hideView('layerMenu'))} title='mapViews.layersHeader'>
      <Stack
        divider={<AppStackDivider />}
        alignContent='center'
      >
        <Stack
          divider={<AppStackDivider />}
          alignItems='center'
          width='100%'>
          {
            Object.values(views).map(view => <LayerItem key={view.name} {...view} />)
          }
        </Stack>
      </Stack>
    </AppDialog>
  )
}

const AddressInput = ({ placeholder, icon, value, setSelected, apiService, valueName }: SearchProps) => {

  const [options, setOptions] = React.useState<readonly AddressType[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  const dispatch = useAppDispatch()

  useEffect(() => {

    const fetchAddresses = async (text: string) => {
      const opts = await apiService.getSuggestedAddresses(text)
      setOptions(opts)
    }

    const valueAsString = (!!!value ? '' : value.label + ', ' + value!.city)

    if (inputValue !== valueAsString && inputValue.length > 2) {
      fetchAddresses(inputValue)
    }

    return () => { setOptions([])}
  }, [value, inputValue, apiService])

  return (
    <Stack direction='row' justifyContent='flex-start' alignItems={'center'}>
      <Autocomplete
        sx={{
          fontSize: '16px',
          fontWeight: 400,
          height: '56px',
          borderRadius: '6px',
        }}
        freeSolo
        size='small'
        fullWidth
        filterSelectedOptions
        value={value || null}
        getOptionLabel={(address: string | AddressType) => {
          if (address === '') return address
          const a = address as AddressType
          return `${a.label}${a.type === 'address' ? ', ' + a.city : ''}`
        }}
        renderInput={(params) => <TextField {...params} label={placeholder} />}
        options={options}
        filterOptions={(x) => x}
        onInputChange={(_, value) => setInputValue(value)}
        onChange={(_, value) => { setSelected(value) }}
        onFocus={() => dispatch(setClickListener(valueName))}
      // onBlur={() => dispatch(setClickListener(null))}
      />
      <span className='material-icons-outlined'>{icon}</span>
    </Stack>
  )
}

const LocationInput = ({ apiService }: any) => {

  const { sourceAddress, destinationAddress } = useAppSelector(selectRoute)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  return (
    <Stack alignContent='center'>
      <Typography component="h1" variant="h1">{t('locationInput.findRoute')}</Typography>
      <AddressInput
        placeholder={t('locationInput.startLocation')}
        // placeholder='Start location...'
        valueName='sourceAddress'
        icon='circle'
        setSelected={(value) => dispatch(setSourceAddress(value))}
        value={sourceAddress}
        apiService={apiService}
      />
      <Stack direction='row' height='23px'>
        <hr style={{ width: '100%', alignSelf: 'center', borderWidth: '0.1em' }} />
        <span className="material-icons-outlined">more_vert</span>
      </Stack>
      <AddressInput
        placeholder={t('locationInput.endLocation')}
        valueName='destinationAddress'
        icon='place'
        setSelected={(value) => dispatch(setDestinationAddress(value))}
        value={destinationAddress}
        apiService={apiService}
      />
    </Stack>
  )
}

const Preferences = () => {

  const { t } = useTranslation()

  return (
    <>
      <ExclusivePreferenceButtons />
      <Typography component="h2">{t('locationInput.routePreferences')}</Typography>
      <PreferenceButtons />
    </>
  )
}

const DirectionForm = ({ apiService }: any) => {

  const { searchForm: { visible } } = useAppSelector(selectUiState)
  const { sourceAddress, destinationAddress } = useAppSelector(selectRoute)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  return (
    !visible ? null :
      <AppBox className='search-form'>
        <Stack>
          <Stack direction="row" justifyContent='space-between' alignItems='center' paddingBottom={'0.5rem'}>
            <Box
              style={{ width: '23%' }}
              component='img'
              src='logo_blue.svg' width={'auto'} height={'auto'}
            >
            </Box>
            <Button
              onClick={() => dispatch(hideView('searchForm'))}
              sx={{
                color: 'rgba(21, 14, 55, 0.85)'
              }}
            >
              <span className="material-icons">close</span>
            </Button>
          </Stack>
          <LocationInput apiService={apiService} />
          <Preferences />
          <AppButton
            disabled={!(sourceAddress && destinationAddress)}
            onClick={() => dispatch(fetchRoute())}
            title={t('locationInput.getRoute')}
            icon='arrow_right_alt'
          />
        </Stack>
      </AppBox>
  )
}

const menuTheme = createTheme({
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          position: 'absolute',
          width: '5rem',
        },
      }
    }
  }
})

export const Menu = () => {

  const dispatch = useAppDispatch()

  const locate = () => {
    dispatch(setAsyncState({ pending: true }))
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords
      dispatch(reverseGeocode({ latitude, longitude, routeEnd: 'sourceAddress' }))
      dispatch(showView('searchForm'))
      dispatch(setAsyncState({ pending: false }))
    })
  }

  return (
    <div className="main-menu">
      <ThemeProvider theme={menuTheme}>
        <ToggleButtonGroup
          sx={{
            position: 'relative',
            alignItems: 'center',
          }}
        >
          {
            isMobileDevice &&
            <ToggleButton
              value="locate"
              onClick={() => locate()}
              sx={{
                left: 0,
                bottom: 0,
                paddingLeft: 0
              }}
            >
              <span className="material-icons">near_me</span>
            </ToggleButton>
          }
          <ToggleButton
            onClick={() => dispatch(showView('searchForm'))}
            value="open"
            sx={{
              zIndex: '102',
              backgroundColor: '#6685FF',
              color: 'white',
              borderRadius: '50% !important',
              height: '3.4rem',
              width: '3.4rem',
              left: '3.1rem',
              bottom: '-0.25rem'
            }}
          >
            <span className="material-icons">search</span>
          </ToggleButton>

          <ToggleButton
            value="layers"
            onClick={() => dispatch(showView('layerMenu'))}
            sx={{
              left: '4.6rem',
              bottom: 0,
              paddingRight: 0
            }}
          >
            <span className="material-icons">layers</span>
          </ToggleButton>
        </ToggleButtonGroup>
      </ThemeProvider>
    </div>
  )
}

export default DirectionForm