import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { showErrorDialog } from "../../app/globalSlice";
import { RootState } from "../../app/store";
import { airQualityTimestamp, ApiService } from "../../service";

export type LayerProps = {
    type: string,
    icon: string,
    color: string,
    title: string,
    infoContent: string,
}

export type MapViewType = {
    name: string,
    label: string,
    styleParams: {
        user: string,
        styleId: string
    },
    infoContent?: string,
}

export const layers: LayerProps[] = [{
    type: 'airQuality',
    icon: 'air',
    color: 'purple',
    title: 'Air Quality',
    infoContent: 'airQualityLayerInformation',
}]

export const views: { [view: string]: MapViewType } = {
    'darkView': {
        name: 'darkView',
        label: 'mapViews.darkView',
        styleParams: {
            user: 'tuomaspiippo',
            styleId: 'clcekzjp400fi14s12swolfys'
        },
        infoContent: 'airQualityLayerInformation'
    },
    'basicView': {
        name: 'basicView',
        label: 'mapViews.basicView',
        styleParams: {
            user: 'tuomaspiippo',
            styleId: 'clfl5138b00aq01s27zl7uvi7'
        },
    },
    'satelliteView': {
        name: 'satelliteView',
        label: 'mapViews.satelliteView',
        styleParams: {
            user: 'tuomaspiippo',
            styleId: 'cl6afzyf8000915ne8vks0eef'
        },
    }
}

export type MapState = {
    layers: { [name: string]: boolean },
    currentView: MapViewType,
    currentTimestamp?: string,
    airQualityData?: GeoJSON.FeatureCollection,
}

const initialState: MapState = {
    layers: {
        airQuality: false
    },
    currentView: views['basicView'],
}

export const setView = createAsyncThunk(
    'route/fetchView',
    async (view:string, { getState, dispatch }) => {

        const { map } = (getState() as RootState)
        const { currentTimestamp, airQualityData } = map
        const timestamp = airQualityTimestamp()

        if (view === 'darkView' && (!currentTimestamp || currentTimestamp !== timestamp)) {

            const aqData = await ApiService().getAirQualityData(timestamp)

            if (Object.keys(aqData).length) {
                return {
                    airQualityData: aqData ? aqData : airQualityData,
                    currentTimestamp: timestamp,
                    currentView: views[view],
                }
            } else {
                dispatch(showErrorDialog({ title: 'error.title.noAqData', content: 'error.content.noAqData' }))
                return {
                    ...map
                }
            }
        } else {
            return {
                ...map,
                currentView: views[view]
            }
        }
    }
)

const mapSlice = createSlice({
    name: 'mapState',
    initialState,

    reducers: {},
    
    extraReducers: (builder) => {
        builder.addCase(setView.fulfilled, (state, action) => ({
            ...state,
            ...action.payload,
            layers: {
                airQuality: action.payload.currentView.name === 'darkView'
            }
}))
    }
})

export const selectLayer = (layer: string) => createSelector((state: RootState) => state.map.layers, (layers: { [name: string]: boolean }) => layers[layer])
export const selectMapView = ({ map }: RootState):MapState => map

export default mapSlice.reducer
