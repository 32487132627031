import mapboxgl, { Map, MapMouseEvent } from 'mapbox-gl';
import { useEffect, useRef } from 'react';
import { selectClickListener, setClickListener } from '../../app/globalSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AppDispatch } from '../../app/store';
import { BoundingBoxType } from '../../types';
import { reverseGeocode, selectRoute } from '../inputform/routeSlice';
import { selectMapView } from '../map/mapSlice';
import { drawOptionalPath, drawPath, setStyle } from './functions';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const TURKU_BB: BoundingBoxType = {
  bottomLeft: {
    longitude: 21.982023629595407,
    latitude: 60.341236305171975
  },
  topRight: {
    longitude: 22.572031135793026,
    latitude: 60.70025546383697
  }
}


const createClickListener = (routeEnd: 'sourceAddress' | 'destinationAddress', dispatch: AppDispatch) => {
  return (e: MapMouseEvent | undefined) => {
    if (!e) return
    const { lngLat: { lng: longitude, lat: latitude } } = e
    dispatch(reverseGeocode({ longitude, latitude, routeEnd }))
    dispatch(setClickListener(null))
  }
}

let currentClickListener: any = null

const RouteMap = () => {

  const clickListener = useAppSelector(selectClickListener)
  const mapContainer = useRef<any>(null);
  const map = useRef<Map>();

  const { route, sourceAddress, fastestRoute } = useAppSelector(selectRoute)
  const { currentView, airQualityData } = useAppSelector(selectMapView)

  const dispatch = useAppDispatch()

  useEffect(() => {

    if (!!!map.current) return
    setStyle(map.current)(currentView, airQualityData)

  }, [currentView])

  useEffect(() => {
    if (map.current) {
      if (currentClickListener) {
        map.current.off('click', currentClickListener)
      }

      if (clickListener) {
        currentClickListener = createClickListener(clickListener!, dispatch)
        map.current.on('click', currentClickListener)
      }
    }
  }, [clickListener, dispatch])

  useEffect(() => {

    if (!(map.current && sourceAddress)) return

    const { longitude, latitude } = sourceAddress!

    map.current.flyTo({ 'center': [longitude, latitude], 'zoom': 19 });
  }, [sourceAddress])

  useEffect(() => {
    if (route?.geometries?.path) {
      drawPath(map.current!)(route)
      if (fastestRoute) {
        drawOptionalPath(map.current!)(fastestRoute)
      }
    }

  }, [route, fastestRoute])

  useEffect(() => {

    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      accessToken: 'pk.eyJ1IjoidHVvbWFzcGlpcHBvIiwiYSI6ImNrMWh3MDhvODBwamUzY3Fwdmlnd3dxdTkifQ.Sk2raQZ8xa45hH0H4YC_1Q',
      zoom: 9,

    })

    setStyle(map.current)(currentView, airQualityData)

    map.current.on('load', () => {

      map.current!.fitBounds([
        [TURKU_BB.bottomLeft.longitude, TURKU_BB.bottomLeft.latitude],
        [TURKU_BB.topRight.longitude, TURKU_BB.topRight.latitude]
      ])
    })

  }, [])

  return (<div ref={mapContainer} className="map-container"></div>)
}

export default RouteMap