import { ThemeProvider } from '@mui/material/styles';
import { Suspense } from 'react';
import './App.css';
import BusyIndicator from './BusyIndicator';
import { selectAsyncState, selectCurrentError, selectUiState } from './app/globalSlice';
import { useAppSelector } from './app/hooks';
import { ErrorDialog } from './components/AppComponents';
import { FeedbackButton, RouteFeedbackForm } from './features/feedback/RouteFeedback';
import { GeneralAppInfoView, SideInfoView } from './features/info/InfoViews';
import LocationInput, { LayerMenu, Menu } from './features/inputform/LocationInput';
import { selectRoute } from './features/inputform/routeSlice';
import Map from './features/map/Map';
import RouteSummary from './features/routesummary/RouteSummary';
import { ApiService } from './service';
import { theme } from './theme';

function App() {

  const apiService = ApiService()

  const async = useAppSelector(selectAsyncState)
  const { routeSummary, errorDialog } = useAppSelector(selectUiState)
  const { currentError } = useAppSelector(selectCurrentError)
  const { route } = useAppSelector(selectRoute)

  return (
    <ThemeProvider theme={theme}>
      <div style={{ cursor: async.pending ? 'wait' : 'default' }}>
        {/*
      It is important for the Map component _NOT_ to reside under the Suspense element for the map to render correctly.
      This might change if Map has something to need translation in the future.
      */}
        <Map />
        <Suspense fallback='... Waiting for translations'>
          <LocationInput apiService={apiService} />
          { routeSummary.visible && route && <RouteSummary /> }
          <Menu />
          <LayerMenu />
          <BusyIndicator />
          <SideInfoView />
          <GeneralAppInfoView />
          { errorDialog.visible && <ErrorDialog title={currentError!.title} content={currentError!.content} /> }
          <RouteFeedbackForm />
          <FeedbackButton />
        </Suspense>
      </div>
    </ThemeProvider>
  );
}

export default App;
