import { RouteWithDistance, TransportModeType } from '@backend/types'
import { Box, Button, Grid, Typography } from '@mui/material'
import i18next from 'i18next'
import { hideView } from '../../app/globalSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { AppBox } from '../../components/AppComponents'
import { selectRoute } from '../inputform/routeSlice'

const [WALKING_SPEED, CYCLING_SPEED] = [1.3888, 4.7222]

type SummaryProps = {
    title: string,
    route: RouteWithDistance,
    exclusiveParameter: TransportModeType,
    color?: string,
    cssClass?: string,
}

const formatDistance = (distanceMeters:number) => {
    return Math.round(distanceMeters / 100) * 100 / 1000 + ' km'
}

const formatTime = (distanceMeters:number, exclusiveParameter:TransportModeType) => {

    const travelTimeMinutes = exclusiveParameter === 'cycling' ? distanceMeters / CYCLING_SPEED / 60 : distanceMeters / WALKING_SPEED / 60

    const [ hours, minutes ] = [ Math.floor(travelTimeMinutes / 60), Math.ceil(travelTimeMinutes % 60) ]

    return `${hours ? hours + ' h ' : ''}${minutes} min`
}

const Summary = ({ title, route, exclusiveParameter, color, cssClass }: SummaryProps) => {

    const { travelDistance } = route

    return (
        <>
            <Grid container marginTop={'5px'}>
                <Grid item xs={6}>
                    <Typography variant='subtitle2'>{title}</Typography>
                    <div
                        className={ cssClass ? cssClass : ''}
                        style={{
                            width: '100px',
                            height: '5px',
                            backgroundColor: color ? color : 'none',
                        }}
                    ></div>
                </Grid>
                <Grid item xs={6}>
                    <Grid item xs={12} alignContent='left'>
                        <Typography>{formatDistance(travelDistance!)}</Typography>
                        <Typography>{formatTime(travelDistance!, exclusiveParameter)}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default () => {

    const dispatch = useAppDispatch()
    const { t } = i18next
    const { sourceAddress, destinationAddress, route, fastestRoute, exclusiveParameter, routeWarning } = useAppSelector(selectRoute)

    return (
        <AppBox className='route-summary'>
            <div>

                <Grid container>
                    <Grid item container xs={12} justifyContent='flex-end'>
                        <Button
                            onClick={() => {
                                dispatch(hideView('routeSummary'))
                                // dispatch(showView('routeFeedback'))
                            }}
                            sx={{
                                color: 'rgba(21, 14, 55, 0.85)'
                            }}
                        >
                            <span className="material-icons">close</span>
                        </Button>                        
                    </Grid>
                    {
                    routeWarning &&
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                color: 'red',
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                                paddingBottom: '0.5rem',
                            }}

                        >{t(routeWarning)}</Box>
                    </Grid>
                    }
                    <Grid item xs={6}>
                        <Typography variant='subtitle2'>{t('routeSummary.fromAddress')}:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>{sourceAddress!.label}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle2'>{t('routeSummary.toAddress')}:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>{destinationAddress!.label}</Typography>
                    </Grid>
                </Grid>

                <Summary { ...{ title: t('routeSummary.currentRoute'), route: route!, exclusiveParameter, color: '#4a80f5' }} />
                {
                    !fastestRoute ? null : (
                        <>
                            {/* <Summary { ...{ title: t('routeSummary.fastestRoute'), route: fastestRoute!, exclusiveParameter, cssClass: 'alternate-route' }} /> */}
                            <Summary { ...{ title: t('routeSummary.fastestRoute'), route: fastestRoute!, exclusiveParameter, color: '#ff6900' }} />
                        </>
                    )
                }
            </div>
        </AppBox>
    )
}