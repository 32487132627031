import { RouteWithDistance } from '@backend/types';
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hideView, showErrorDialog, showView } from '../../app/globalSlice';
import { RootState } from '../../app/store';
import { airQualityTimestamp, ApiService } from '../../service';
import { AddressType, ExclusiveRouteParametersType, RouteParametersKeyType, RouteParametersType, TransportModeType } from '../../types';

export type RouteState = {
    sourceAddress?: AddressType,
    destinationAddress?: AddressType,
    route?: RouteWithDistance,
    fastestRoute?: RouteWithDistance,
    routeParameters: RouteParametersType,
    exclusiveParameter: TransportModeType,
    routeWarning?: string,
}

export const routeParameters: RouteParametersType = {
    'airQuality': {
        id: 'air',
        label: 'locationInput.airQuality',
        value: 0,
        icon: 'air',
        color: 'purple'
    },
    'greenness': {
        id: 'green',
        label: 'locationInput.greenness',
        value: 0,
        icon: 'park',
        color: 'green'
    },
    'elevation': {
        id: 'elevation',
        label: 'locationInput.elevation',
        value: 0,
        icon: 'terrain',
        color: 'red'
    },
}

export const exclusiveRouteParameters: { [type:string]: ExclusiveRouteParametersType } = {
    walking: {
        id: 'walking',
        label: 'locationInput.walking',
        icon: 'directions_walk',
        color: 'blue'
    },
    cycling: {
        id: 'cycling',
        label: 'locationInput.cycling',
        icon: 'directions_bike',
        color: 'blue'
    },
}

const initialState: RouteState = {
    routeParameters: routeParameters,
    exclusiveParameter: 'walking',
}

const DEFAULT_PARAMETERS = Object.values(routeParameters).reduce((prevParam, currParam) => ({ ...prevParam, [currParam.id]: { ...currParam, value: 100 }}), {} as any)
const hasRoutePreference = (routeParameters:RouteParametersType) => {
    return !!Object.values(routeParameters).find(parameter => parameter.value > 0)
}

export const fetchRoute = createAsyncThunk(
    'route/fetchRoute',
    async (_, { getState, dispatch }) => {

        const state: RouteState = (getState() as RootState).route
        const preferenceChosen = hasRoutePreference(state.routeParameters)

        const { sourceAddress, destinationAddress, exclusiveParameter } = state
        const timestamp = airQualityTimestamp()
        
        const [ fastestRoute, preferencedRoute ] = await Promise.all([
            ApiService().getShortestPath(timestamp, sourceAddress!.uuid, destinationAddress!.uuid, exclusiveParameter),
            ...(preferenceChosen ? [ ApiService().getPath(timestamp, sourceAddress!.uuid, destinationAddress!.uuid, exclusiveParameter, state.routeParameters) ] : [])
        ])

        const routeData = {
            route: {
                ...state.route,
                ...(preferencedRoute ? preferencedRoute : fastestRoute)
            },
            fastestRoute: !preferencedRoute ? undefined : fastestRoute,
        }

        if (preferencedRoute && !Object.keys(preferencedRoute).length) {
            if (!fastestRoute || !Object.keys(fastestRoute)) {
                dispatch(showErrorDialog({ title: 'error.title', content: 'error.content' }))
                return { ...state }
            } else {
                return {
                    ...routeData,
                    routeWarning: 'error.noAirQualityProjected'
                }
            }

        }

        dispatch(hideView('searchForm'))
        dispatch(showView('routeSummary'))

        return {
            ...routeData,
            routeWarning: 'error.noAirQualityProjected',
        }
    }
);

export const reverseGeocode = createAsyncThunk(
    'route/reverseGeocode',
    async ({ longitude, latitude, routeEnd }: { longitude: number, latitude: number, routeEnd: 'sourceAddress' | 'destinationAddress' }):Promise<{ address: AddressType, routeEnd:string }> => {
        
        const address = await ApiService().reverseGeocode(longitude, latitude)
        
        return {
            address: {
                ...address,
                longitude,
                latitude,
            },
            routeEnd,
        }
    }
);

const routeSlice = createSlice({
    name: 'routeState',
    initialState,
    reducers: {
        setSourceAddress: (state, { payload }) => {
            return {
                ...state,
                sourceAddress: payload,
            }
        },
        setDestinationAddress: (state, { payload }) => {
            return {
                ...state,
                destinationAddress: payload,
            }
        },
        setRouteParameter: (state, action:PayloadAction<{ id: RouteParametersKeyType, value:number }>) => {
            
            const { id, value } = action.payload
            const parameter = {
                ...state.routeParameters[id],
                value
            }

            return {
                ...state,
                routeParameters: {
                    ...state.routeParameters,
                    [id]: parameter
                }
            }
        },
        setExclusiveRouteParameter: (state, action) => ({ ...state, exclusiveParameter: action.payload }),
    },

    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {

        builder.addCase(fetchRoute.fulfilled, (state, { payload }) => {
            const { route, fastestRoute } = payload
            return {
                ...state,
                route,
                fastestRoute,
            }
        })

        builder.addCase(reverseGeocode.fulfilled, (state, action) => {

            const { address, routeEnd } = action.payload

            return {
                ...state,
                [routeEnd]: address,
            }
        })
    },
});

export const { setDestinationAddress, setSourceAddress, setRouteParameter, setExclusiveRouteParameter } = routeSlice.actions
export const selectRoute = (state: RootState) => { return state.route }

export const selectRouteParameter = (id:RouteParametersKeyType) => createSelector((state: RootState) => state.route.routeParameters, (params:RouteParametersType) => params[id].value)
export const selectExclusiveRouteParameter = ({ route }: RootState) => route.exclusiveParameter

export default routeSlice.reducer