export const viewContent = (lngOrLocale: string) => content[lngOrLocale.substring(0, 2)]

const content = {
    fi: {
        helpText: `
        <div>
        <p>AirQu etsii sopivimman pyöräily- tai kävelyreitin juuri sinun tarkoituksiisi. Voit valita reitillesi yhden tai useamman hakukriteerin, jotka huomioidaan reitin valinnassa.</p>
        <p><b>Ilmanlaatu.</b> Etsii reitin, jonka varrella on mahdollisimman hyvä ilmanlaatu. Ilmanlaatu määritellään tunneittain päivittyvällä, Ilmatieteenlaitoksen laskemalla ilmanlaatuindeksillä.</p>
        <p><b>Vehreys.</b> Etsii reitin suosien viheralueita, kuten puistoja ja metsiä.</p>
        <p><b>Tasainen reitti.</b> Etsii reitin vältellen korkeuseroja reitillä.</p>
        <p><b>Ilman kriteereitä</b> sovellus etsii lyhimmän reitin lähtöpisteestä määränpäähän.</p>
        <p><b>Useampaa kriteeriä</b> käyttäen sovellus etsii reitin painottaen kutakin kriteeriä tasavertaisesti.</p>
        </div>
        `,
        generalAppInformation: `
        <img style="max-width: 100%" src='./eu-disclaimer-scaled.png' />
        `,
        airQualityLayerInformation: `
        <div>
        <h2>Ilmansaasteiden terveysvaikutukset</h2>
        <p>Ilmansaasteille herkillä ihmisillä voi ilmetä terveyshaittoja kun ilmanlaatu on huono tai erittäin huono. Eri saasteilla voi olla erilaisia haittavaikutuksia ja oireet ovat erittäin yksilöllisiä.</p>
        <p>Hengitettävät hiukkaset (PM10). Herkät hengitystiesairaat, erityisesti astmaatikot, sekä pikkulapset voivat saada oireita: nuhaa, yskää sekä kurkun ja silmien kutinaa ja hengitysoireita.</p>
        <p>Pienhiukkaset (PM2.5). Astmaatikot sekä yleensä iäkkäät sepelvaltimotautia ja keuhkoahtaumatautia sairastavat voivat saada hengitystie- ja sydänoireita sekä heidän keuhkojen ja sydämen toimintakykynsä voi heiketä. Myös terveet voivat kokea silmien, nenän ja kurkun ärsytystä tai lievää hengenahdistusta.</p>
        <p>Typpidioksidi (NO2). Astmaatikot sekä yleensä iäkkäät sepelvaltimotautia ja keuhkoahtaumatautia sairastavat voivat saada hengitystie- ja sydänoireita sekä heidän keuhkojen ja sydämen toimintakykynsä voi heiketä. Herkkyys pakkaselle ja siitepölylle saattaa lisääntyä.</p>
        <p>Rikkidioksidi (SO2). Korkeat rikkidioksidipitoisuudet voivat lisätä lasten ja aikuisten hengitystieinfektioita sekä astmaatikkojen kohtauksia. Äkillisiä oireita ovat yskä, hengenahdistus ja keuhkoputkien supistuminen. Astmaatikot ovat muita herkempiä rikkidioksidin vaikutuksille ja erityisesti pakkanen voi pahentaa rikkidioksidin aiheuttamia oireita.</p>
        <p>Otsoni (O3). Korkeat otsonipitoisuudet voivat aiheuttaa silmien, nenän ja kurkun limakalvojen ärsytystä. Hengityssairailla voivat myös yskä ja hengenahdistus lisääntyä ja toimintakyky heikentyä. Otsoni voi pahentaa siitepölyn aiheuttamia allergiaoireita.</p>
        
        <h2>Ilmanlaatuindeksi</h2>
        
        <p>Ilmanlaatuindeksiä käytetään päivittäisessä ilmanlaatutiedotuksessa. Sen avulla ilmanlaatu voidaan tiivistää havainnolliseen väriasteikkoon ja laatusanoihin hyvä, tyydyttävä, välttävä, huono tai erittäin huono. Indeksi on tunneittain laskettava vertailuluku, joka kuvaa sen hetkistä ilmanlaatua suhteutettuna ilmanlaadun ohje- ja raja-arvoihin.</p>

        <div class="air-quality-colors">
            <span class="color good"></span> hyvä<br>
            <span class="color satisfactory"></span> tyydyttävä<br>
            <span class="color fair"></span> välttävä<br>
            <span class="color poor"></span> huono<br>
            <span class="color very-poor"></span> erittäin huono<br>
        </div>
        
        <p><b>hyvä</b> ei todettuja terveysvaikutuksia, lieviä luontovaikutuksia pitkällä aikavälillä</p>
        <p><b>tyydyttävä</b> hyvin epätodennäköisiä terveysvaikutuksia, lieviä luontovaikutuksia pitkällä aikavälillä</p>
        <p><b>välttävä</b> terveysvaikutukset epätodennäköisiä, selviä kasvillisuus- ja materiaalivaikutuksia pitkällä aikavälillä</p>
        <p><b>huono</b> mahdollisia terveysvaikutuksia herkillä ihmisillä, selviä kasvillisuus- ja materiaalivaikutuksia pitkällä aikavälillä</p>
        <p><b>erittäin huono</b> mahdollisia terveysvaikutuksia herkillä väestöryhmillä, selviä kasvillisuus- ja materiaalivaikutuksia pitkällä aikavälillä</p>`,
    },
    en: {
        helpText: `
        <div>
        <p>This application helps you find the most suitable cycling and walking routes for you. You can choose one or more criteria according to your preferences, which will be taken into account when choosing the route.</p>
        <p><b>Air quality.</b> Look for a route where the air quality index calculated by the Finnish Meteorological Institute is as good as possible.</p>
        <p><b>Greenery.</b> Look for a route that goes through green areas such as parks and forests.</p>
        <p><b>Flat route</b>. Avoid extra height differences on the route.</p>
        <p>If you do not select any of these criteria, the application will find the shortest route for you between the starting point and the destination.</p>
        </div>
        `,
        airQualityLayerInformation: `
        <div>
        
        <h2>Health effects of air pollution</h2>
        <p>People sensitive to air pollution may experience health problems when the air quality is bad or very bad. Different pollutants can have different adverse effects and the symptoms are very individual.</p>
        
        <p>Inhalable particles (PM10). Sensitive respiratory patients, especially asthmatics, as well as small children may experience symptoms: runny nose, cough, and itchy throat and eyes and respiratory symptoms.</p>
        
        <p>Small particles (PM2.5). Asthmatics and generally elderly people with coronary artery disease and COPD can have respiratory and heart symptoms, and their lung and heart function can decrease. Even healthy people can experience eye, nose and throat irritation or mild shortness of breath.</p>
        
        <p>Nitrogen dioxide (NO2). Asthmatics and generally elderly people with coronary artery disease and COPD can have respiratory and heart symptoms, and their lung and heart function can decrease. Sensitivity to frost and pollen may increase.</p>
        
        <p>Sulfur dioxide (SO2). High concentrations of sulfur dioxide can increase respiratory infections in children and adults, as well as attacks in asthmatics. Sudden symptoms include cough, shortness of breath and bronchoconstriction. Asthmatics are more sensitive to the effects of sulfur dioxide than others, and cold weather in particular can worsen the symptoms caused by sulfur dioxide.</p>
        
        <p>Ozone (O3). High ozone concentrations can cause irritation of the mucous membranes of the eyes, nose and throat. In respiratory patients, cough and shortness of breath may also increase and functional capacity may decrease. Ozone can worsen allergy symptoms caused by pollen.</p>
        
        <h2>Air Quality Index</h2>
        <p>Air quality index is used to describe the air quality in simple terms and an easy-to-understand color scale. It is based on measured air quality data and gives an overall characterization of the actual air quality. Finnish air quality index is a hourly index which describes the air quality today, based on hourly values and updated every hour.</p>
        
        <div class="air-quality-colors">
            <span class="color good"></span> good<br>
            <span class="color satisfactory"></span> satisfactory<br>
            <span class="color fair"></span> fair<br>
            <span class="color poor"></span> poor<br>
            <span class="color very-poor"></span> very poor<br>
        </div>
        
        <p><b>good</b> no known health impacts, mild environmental impacts</p>
        <p><b>satisfactory</b> health impacts very unlikely, mild environmental impacts</p>
        <p><b>fair</b> health impacts unlikely clear impacts in vegetation and materials</p>
        <p><b>poor</b> sensitive individuals may experience adverse effects, clear impacts in vegetation and materials</p>
        <p><b>very poor</b> sensitive population may experience adverse effects, clear impacts in vegetation and materials</p>
        </div>`,
        generalAppInformation: `<img style="max-width: 100%" src='./eu-disclaimer-scaled.png' />`
    }
} as {
    [language: string]: { [key: string]: string }
}