import { Button, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { hideView, selectUiState, sendRouteFeedback, showView } from "../../app/globalSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import AppDialog from "../../components/AppComponents"

type StartPropsType = {
    chosen: boolean,
    setChosen: (chosen:boolean) => void
}

const Star = ({ chosen, setChosen }:StartPropsType) => {
    return (
        <span
            className={chosen ? 'material-icons' : 'material-icons-outlined'}
            onMouseOver={() => setChosen(true)}
            onMouseOut={() => setChosen(false)}
        >
            grade
        </span>
    )
}

type StarPropsType = {
    starState: boolean[],
    setStarState: (stars:boolean[]) => void,
    freeze: boolean,
    setFreeze: (freeze:boolean) => void,
}

const Stars = ({ starState, setStarState, freeze, setFreeze }:StarPropsType) => {

    const setChosen = (starIdx:number, value:boolean) => {
        setStarState(starState.map((_, idx) => starIdx >= idx ? value : false))
    }

    return <div
                style={{ cursor: 'pointer' }}
                onClick={() => setFreeze(true)}
            >
            {
                starState.map(
                    (chosen, idx:number) => (
                        <Star key={idx} chosen={chosen} setChosen={(value) => freeze ? () => null : setChosen(idx, value)} />
                    ))
            }
            </div>

}

type ReviewPropsType = {
    review: string,
    setReview: (review:string) => void,
}

const Review = ({ review, setReview}:ReviewPropsType) => {
    return (
        <TextField
            multiline
            fullWidth
            rows={5}
            placeholder={'Annathan muutaman sanan palautetta'}
            value={review}
            onChange={(e) => setReview(e.target.value)}
        />
    )
}

export const RouteFeedbackForm = () => {

    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const { routeFeedback: { visible } } = useAppSelector(selectUiState)

    const [ starState, setStarState ] = useState<boolean[]>(Array(5).fill(false))
    const [ freeze, setFreeze ] = useState<boolean>(false)
    const [ review, setReview ] = useState<string>('')

    const countStars = () => starState.reduce((prev, curr) => prev + (curr ? 1 : 0), 0)

    const resetForm = () => {
        setStarState(Array(5).fill(false))
        setReview('')
        setFreeze(false)
    }

    return (
        <AppDialog
            open={visible}
            onClose={() => dispatch(hideView('routeFeedback'))}
            title='Miten reitti onnistui?'
        >
            <Stars starState={starState} setStarState={setStarState} freeze={freeze} setFreeze={setFreeze} />
            <Review review={review} setReview={setReview} />
            {
                freeze &&
                <>
                    <Typography>Kiitos palautteesta</Typography>
                    <Button
                        style={{ float: 'right' }}
                        variant="contained"
                        onClick={() => {
                            dispatch(hideView('routeFeedback'));
                            dispatch(sendRouteFeedback({ points: countStars(), review }))
                            resetForm()
                        }}>{t('sendFeedback')}</Button>
                </>
            }

        </AppDialog>
    )
}

export const FeedbackButton = () => {
    
    const dispatch = useAppDispatch()

    return (
        <Button
            onClick={() => dispatch(showView('routeFeedback'))}
            sx={{
                backgroundColor: 'white',
                color: 'rgba(21, 14, 55, 0.85)',
                float: 'right',
                bottom: '4.5rem',
                right: '0.5rem',
                padding: '0.5rem',
                borderRadius: '35%',
                minWidth: 0,
                '&:hover': { backgroundColor: 'white' },
            }}>
                <span className="material-icons">reviews</span>
        </Button>
    )
}