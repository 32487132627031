import { RouteFeedbackRequestType } from '@backend/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ApiService } from '../service';

import sanitizeHtml from 'sanitize-html';

type GlobalView = {
    visible: boolean,
    // Does opening the view close other views?
    exclusive?: boolean,
}

type GlobalViews = {
    routeSummary: GlobalView,
    searchForm: GlobalView,
    layerMenu: GlobalView,
    sideMenu: GlobalView,
    infoView: GlobalView,
    routeFeedback: GlobalView,
    errorDialog: GlobalView,
}

type ViewName = keyof GlobalViews

export type GlobalState = {
    ui: {
        async: {
            pending: boolean,
            error: boolean
        },
        clickListener: 'sourceAddress' | 'destinationAddress' | null,
        views: GlobalViews,
        infoViewContent: string,
        infoViewTitle?: string,    
        currentError?: {
            title: string,
            content: string,
        },
        }
}

const initialState: GlobalState = {
    ui: {
        async: {
            pending: false,
            error: false,
        },
        clickListener: null,
        views: {
            routeSummary: { visible: false, exclusive: true },
            searchForm: { visible: false, exclusive: true },
            layerMenu: { visible: false, exclusive: true },
            sideMenu: { visible: false },
            infoView: { visible: false, exclusive: true },
            routeFeedback: { visible: false },
            errorDialog: { visible: false },
        },
        infoViewContent: '',
    },
}

export const sendRouteFeedback = createAsyncThunk(
    'route/feedback',
    async ({ points, review }:{ points:number, review:string }, { getState }) => {

        const { route } = getState() as RootState
        const feedback = {
            routeParameters: Object.values(route.routeParameters).filter(param => param.value).map(param => param.id),
            transportMode: route.exclusiveParameter,
            destinationLocationUid: route.destinationAddress?.uuid,
            startLocationUuid: route.sourceAddress?.uuid,
            points,
            review: sanitizeHtml(review, { allowedTags: []}),
        } as RouteFeedbackRequestType
        ApiService().sendRouteFeedback(feedback)
    }
)

const setAllFalse = (views: GlobalViews):GlobalViews => ({
    ...Object.entries(views).map(([ key, value ]) => ({ [key]: { ...value, visible: false} })).reduce((prev, curr) => ({ ...prev, ...curr })) as GlobalViews
})

const globalSlice = createSlice({
    name: 'globalState',
    initialState,
    reducers: {
        setClickListener: (state, action) => {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    clickListener: action.payload
                }
            }},
        setAsyncState: (state, action) => ({
            ...state,
            ui: {
                ...state.ui,
                async: {
                    ...action.payload
                }
            }
        }),
        showView: (state, { payload }) => {

            const viewName = payload as ViewName
            const { exclusive } = state.ui.views[viewName]!!!

            return {
                ...state,
                ui: {
                    ...state.ui,
                    views: {
                        ...(exclusive ? setAllFalse(state.ui.views) : state.ui.views),
                        [viewName]: {
                            ...state.ui.views[viewName],
                            visible: true,
                        },
                    }
                }
            }
        },
        hideView: (state, { payload }) => {

            const viewName = payload as ViewName

            return {
                ...state,
                ui: {
                    ...state.ui,
                    views: {
                        ...state.ui.views,
                        [viewName]: {
                            ...state.ui.views[viewName],
                            visible: false,
                        },
                    }
                }
            }
        },
        showInfoView: (state, action) => ({
            ...state,
            ui: {
                ...state.ui,
                views: {
                    ...setAllFalse(state.ui.views),
                    infoView: { visible: true },
                },
                infoViewContent: action.payload.content,
                infoViewTitle: action.payload.title
        }
        }),
        hideInfoView: (state) => ({
            ...state,
            ui: {
                ...state.ui,
                views: {
                    ...state.ui.views,
                    infoView: { visible: false },
                }
            }
        }),
        showErrorDialog: (state, { payload }) => ({
            ...state,
            ui: {
                ...state.ui,
                currentError: {
                    title: payload.title,
                    content: payload.content,
                },
                views: {
                    ...state.ui.views,
                    errorDialog: { visible: true },
                    },
            },
        }),
        hideErrorDialog: (state) => ({
            ...state,
            ui: {
                ...state.ui,
                currentError: undefined,
                views: {
                    ...state.ui.views,
                    errorDialog: { visible: false },
                },
            }
        }),
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
    },
});

export const {
    setClickListener,
    setAsyncState,
    showView,
    hideView,
    showInfoView,
    hideInfoView,
    showErrorDialog,
    hideErrorDialog,
} = globalSlice.actions

export const selectAsyncState = ({ global: { ui: { async } } }: RootState) => async
export const selectUiState = ({ global: { ui: { views }} }: RootState) => views

export const selectInfoContent = ({ global: { ui} }: RootState) => {
    const { infoViewContent, infoViewTitle } = ui
    return { infoViewContent, infoViewTitle }
}

export const selectCurrentError = ({ global: { ui} }: RootState) => {
    const { currentError } = ui
    return { currentError }
}

export const selectClickListener = ({ global: { ui: { clickListener }} }: RootState): 'sourceAddress' | 'destinationAddress' | null => clickListener

export default globalSlice.reducer