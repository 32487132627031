import { RouteWithDistance } from "@backend/types";
import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import { LngLatBoundsLike, Map } from 'mapbox-gl';
import { ApiService } from "../../service";
import { MapViewType } from "./mapSlice";

const BB_DELTA: number = 0.0009

export const setStyle = (map:Map) => async (currentView:MapViewType, airQualityData:FeatureCollection<Geometry, GeoJsonProperties> | undefined) => {

    const { sources, layers } = map.getStyle() || { sources: {}, layers: [] }

    const currentSources = Object.entries(sources).filter(([key]) => key !== 'composite' && !key.startsWith('mapbox') && key !== 'airQualitySource').reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {})
    const currentLayers = layers.filter((layer: any) => Object.keys(currentSources).includes(layer.source))

    const newStyle = await ApiService().getMapStyle(currentView)

    newStyle.sources = { ...newStyle.sources, ...currentSources }
    newStyle.layers = [...newStyle.layers, ...currentLayers]

    map.setStyle(newStyle)

    if (currentView.name === 'darkView') {
      if (airQualityData && Object.keys(airQualityData).length)
        map.once('styledata', (e: any) => addAirQualityLayer(map)(airQualityData))
    }
  }


export const drawStartAndEnd = (map: Map) => (start: number[], destination: number[]) => {

    map.addSource('startSource', {
        type: 'geojson',
        data: {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": start
            },
            properties: null
        },
    })

    map.addSource('destinationSource', {
        type: 'geojson',
        data: {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": destination
            },
            properties: null
        }
    })

    map.addLayer({
        'id': 'startLayer',
        'type': 'symbol',
        'source': 'startSource',
        'layout': {
            'icon-image': 'location_on_black_edited_24dp',
            'icon-size': 2,
            'icon-anchor': 'bottom',
        },
    })

    map.addLayer({
        'id': 'destinationLayer',
        'type': 'symbol',
        'source': 'destinationSource',
        'layout': {
            'icon-image': 'sports_score_black_edited_24dp',
            'icon-size': 2,
            'icon-anchor': 'bottom',
        },
    })
}

export const drawOptionalPath = (map: Map) => (path: RouteWithDistance) => {

    const mapObjectName = 'trace_optional'

    if (map.getLayer(mapObjectName)) {
        map.removeLayer(mapObjectName)
        map.removeLayer(mapObjectName + '_dashed')
        map.removeSource(mapObjectName)
    }

    const data = path.geometries!.path!
    map.addSource(mapObjectName, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: data,
                    properties: null,
                }
            ]
        }
    });

    map.addLayer({
        'id': mapObjectName,
        'type': 'line',
        'source': mapObjectName,
        'paint': {
            // 'line-color': '#4a80f5',
            'line-color': '#ff6900',
            'line-opacity': 0.7,
            'line-width': 7,
        }
    });

    // map.addLayer({
    //     'id': mapObjectName + '_dashed',
    //     'type': 'line',
    //     'source': mapObjectName,
    //     'paint': {
    //         'line-color': '#F7FF00',
    //         // 'line-color': '#ff6900',
    //         'line-opacity': 1,
    //         'line-width': 7,
    //         'line-dasharray': [1, 1],
    //     }
    // });

}

export const drawPath = (map: Map) => (path: RouteWithDistance) => {

    const mapObjectName = 'trace'

    if (map.getLayer(mapObjectName)) {
        map.removeLayer(mapObjectName)
        map.removeLayer('startLayer')
        map.removeSource('startSource')
        map.removeLayer('destinationLayer')
        map.removeSource('destinationSource')
        map.removeSource(mapObjectName)
    }

    const data = path.geometries!.path!
    map.addSource(mapObjectName, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: data,
                    properties: null,
                }
            ]
        }
    });

    map.addLayer({
        'id': mapObjectName,
        'type': 'line',
        'source': mapObjectName,
        'paint': {
            'line-color': '#4a80f5',
            // 'line-color': '#ff6900',
            'line-opacity': 1,
            'line-width': 7,
        }
    });

    const [startPoint, endPoint] = [data!.coordinates[0], data!.coordinates[data!.coordinates.length - 1]]
    drawStartAndEnd(map)(startPoint, endPoint);

    const [swLon, swLat, neLon, neLat] = path.geometries!.bbox
    const bbox = [swLon - BB_DELTA, swLat - BB_DELTA, neLon + BB_DELTA, neLat + BB_DELTA]

    map.fitBounds([
        bbox.slice(0, 2),
        bbox.slice(2)
    ] as LngLatBoundsLike)
}

export const addAirQualityLayer = (map: Map) => (data: GeoJSON.FeatureCollection) => {

    map.addSource('airQualitySource', {
        type: 'geojson',
        data: data
    })

    map.addLayer({
        "id": "airQualityLayer",
        "type": "line",
        "source": "airQualitySource",
        "filter": ["has", "airQuality"],
        "layout": {},
        //   "minZoom": 9,
        "paint": {
            "line-color": [
                "interpolate",
                ["linear"],
                ["get", "airQuality"],
                1,
                "#67E567",
                2,
                "#FFF055",
                3,
                "#FFBB58",
                4,
                "#FE4543",
                5,
                "#B5468B"
            ],
            "line-opacity": 0.7,
            "line-width": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                1,
                10,
                2,
                22,
                2
            ]
        }
    });

    moveLayers(map)(['trace', 'trace_optional', 'trace_optional_dashed', 'startLayer', 'destinationLayer'])
}

const moveLayers = (map: any) => (layers: string[]) => layers.forEach(layer => { if (map.getLayer(layer)) map.moveLayer(layer) })
