import React from 'react';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { exclusiveRouteParameters, routeParameters, selectExclusiveRouteParameter, selectRouteParameter, setExclusiveRouteParameter, setRouteParameter } from './routeSlice';
import { useTranslation } from 'react-i18next';

const PreferenceButton = ({ id, parameters, selectParameter, setParameter }: any) => {

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const stateValue = useAppSelector(selectParameter(id));

  const { label, icon, color } = parameters[id];

  return (
    <ToggleButton
      value={id}
      selected={!!stateValue}
      onChange={() => dispatch(setParameter({ id, value: !stateValue ? 100 : 0 }))}
    >
      <span className="material-icons" style={{ color: color }}>{icon}</span>&nbsp;
      <Typography>{t(label)}</Typography>
    </ToggleButton>
  );
};

export const PreferenceButtons = () => {

  return (
    <Box>
      {Object.keys(routeParameters).map(id => (
        <PreferenceButton
          key={id}
          id={id}
          parameters={routeParameters}
          selectParameter={selectRouteParameter}
          setParameter={setRouteParameter} />
      ))}
    </Box>
  );
};

export const ExclusivePreferenceButtons = () => {

  const { t } = useTranslation()
  const selectedParam = useAppSelector(selectExclusiveRouteParameter)
  const dispatch = useAppDispatch()

  return (
    <Box>
      <ToggleButtonGroup exclusive value={selectedParam} onChange={(_, value) => dispatch(setExclusiveRouteParameter(value))}>
      {
        Object.values(exclusiveRouteParameters).map(param => {

          const { id, color, icon, label } = param
          
          return (
            <ToggleButton
              key={id}
              value={id}
            >
              <span className="material-icons" style={{ color: color }}>{icon}</span>&nbsp;
              <Typography>{t(label)}</Typography>            
            </ToggleButton>
        )
      })
      }
      </ToggleButtonGroup>
    </Box>
  )
}

