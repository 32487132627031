import { RouteFeedbackRequestType, RouteWithDistance } from '@backend/types';
import axios from 'axios';
import { MapViewType } from './features/map/mapSlice';
import { AddressType, ApiServiceType, CoordinateType, RouteParametersType, TransportModeType } from './types';

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT + '/api'
const accessToken = 'pk.eyJ1IjoidHVvbWFzcGlpcHBvIiwiYSI6ImNrMWh3MDhvODBwamUzY3Fwdmlnd3dxdTkifQ.Sk2raQZ8xa45hH0H4YC_1Q'

export function ApiService(): ApiServiceType {

  const axiosInstance = axios.create()

  return {

    getPath: async (timestamp: string, sourceUuid: string, destinationUuid: string, transportMode: TransportModeType, parameters: RouteParametersType): Promise<RouteWithDistance> => {

      const paramsString = Object.values(parameters).map(p => p.value).join('/')

      try {

        const response = await axiosInstance.get<RouteWithDistance>(
          `${ENDPOINT}/route/projected/${timestamp}/${transportMode}/${sourceUuid}/${destinationUuid}/0/${paramsString}`
        )

        return response.data

      } catch (error) {
        const status:number = (error as any).response.status
        if (status === 404) return {} as RouteWithDistance
        else throw error
      }
    },

    getShortestPath: async (timestamp:string, sourceUuid:string, destinationUuid:string, transportMode: TransportModeType): Promise<RouteWithDistance> => {

      const response = await axiosInstance.get<RouteWithDistance>(
        `${ENDPOINT}/route/projected/${timestamp}/${transportMode}/${sourceUuid}/${destinationUuid}/100/0/0/0`,
        { headers: { 'Log-Ignore': true }}
      )

      return response.data
    },

    getSuggestedAddresses: async (text: string): Promise<AddressType[]> => {
      return (await axiosInstance.get<AddressType[]>(`${ENDPOINT}/address?search=${text}`)).data
    },

    reverseGeocode: async (longitude: number, latitude: number): Promise<AddressType> => {
      return (await axiosInstance.get<AddressType>(
        `${ENDPOINT}/address/reverse-geocode/${longitude}/${latitude}`
      )).data
    },

    geocode: async (address: string): Promise<CoordinateType> => {
      return (await axiosInstance.get<CoordinateType>(
        `${ENDPOINT}/address/geocode/${address}`
      )).data
    },

    getMapStyle: async ({ styleParams: { user, styleId } }: MapViewType) => {
      return (await axiosInstance.get(
        `https://api.mapbox.com/styles/v1/${user}/${styleId}?access_token=${accessToken}`
      )).data
    },

    getAirQualityData: async (timestamp: string) => {
      return (await axiosInstance.get<GeoJSON.FeatureCollection>(
        `${ENDPOINT}/tileset/${timestamp}`
      )).data
    },
  
    sendRouteFeedback: async (feedback:RouteFeedbackRequestType):Promise<void> => {
      return (await axiosInstance.post(`${ENDPOINT}/feedback/route`, feedback))
    },  
  }
}

const paramTimestamp = new URLSearchParams(window.location.search).get("timestamp")
export const airQualityTimestamp = () => paramTimestamp ? paramTimestamp : new Date().toISOString().slice(0, 13)