import { Box, Button, Dialog, DialogContent, Stack, Typography } from '@mui/material'
import Grid from '@mui/system/Unstable_Grid/Grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { hideErrorDialog } from '../app/globalSlice'
import { useAppDispatch } from '../app/hooks'

type AppBoxProps = {
    className?: string,
    children: React.ReactNode,
    sx?: { [key: string]: any }
}

export const AppStackDivider = () => <hr style={{ border: '1px solid #D8E3F3', width: '97,5%' }} />

export const AppBox = ({ sx = {}, className, children }: AppBoxProps) => {
    return (
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box
            // className={className}
            sx={{
                fontSize: '0.5em',
                borderRadius: '20px',
                padding: '16px',
                justifyContent: 'flex-end',
                backgroundColor: 'white',

                zIndex: 1000,
                position: 'absolute',
                left: 0,
                bottom: 0,
                
                ...sx
            }}
        >
            {children}
        </Box>
        </Grid>
    )
}

type AppDialogProps = {
    className?: string,
    children: React.ReactNode,
    open: boolean,
    onClose: (event?: any, reason?: any) => void,
    title?: string
}

const AppDialog = ({ open, onClose, title, children }: AppDialogProps) => {

    const { t } = useTranslation()

    return (
        <Dialog
            PaperProps={{
                style: {
                    borderRadius: '1rem',
                }
            }}
            fullWidth
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={!title ? onClose : undefined}
        >
            <DialogContent>
                {
                    title &&
                    <Stack>
                        <Box sx={{
                            textAlign: 'center',
                            width: '100%'
                        }}>
                            <span
                                style={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    color: '#150E37',
                                    position: 'absolute',
                                    left: '20px',
                                    cursor: 'pointer',
                                }}
                                onClick={onClose}
                                className='material-icons'>close</span>
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        color: '#150E37',
                                    }}
                                >
                                    {t(title)}
                                </Typography>
                                <AppStackDivider />
                            </>
                        </Box>
                    </Stack>
                }
                {children}
            </DialogContent>
        </Dialog>
    )
}

type ErrorDialogProps = {
    title: string,
    content: string,
}

export const ErrorDialog = ({ title, content }:ErrorDialogProps) => {

    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    return <AppDialog open={true} onClose={() => dispatch(hideErrorDialog())} title={title}><div>{t(content)}</div></AppDialog>
}

type AppButtonProps = {
    disabled?: boolean,
    onClick: () => void,
    title: string,
    icon: string
}

export const AppButton = ({ disabled = false, onClick, title, icon }: AppButtonProps) => {
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            variant="contained"
            sx={{
                fontSize: '16px',
                fontWeight: 500,
                backgroundColor: '#6685FF',
                borderRadius: '999px',
                alignSelf: 'flex-end',
                textTransform: 'none',
            }}
        >
            <Typography>{title}</Typography> <span className='material-icons'>{icon}</span>
        </Button>
    )
}

export default AppDialog