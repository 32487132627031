import { configureStore, ThunkAction, Action, isRejected, createListenerMiddleware } from '@reduxjs/toolkit';

import {
  MiddlewareAPI,
  Middleware,
} from '@reduxjs/toolkit'

import routeSlice from '../features/inputform/routeSlice';
import globalSlice, { setAsyncState } from './globalSlice';
import mapSlice from '../features/map/mapSlice'

const busyListener = createListenerMiddleware()

const requestRegister = (function() {

  let pendingRequests:string[] = []

  return {

    addRequest: (id:string) => {
      pendingRequests.push(id)
    },

    removeRequest: (id:string) => {
      pendingRequests = pendingRequests.filter(requestId => requestId !== id)
    },

    isEmpty: () => !pendingRequests.length
  }
})()

busyListener.startListening({
  predicate: (action, currentState, previousState) => !!action.meta,
  effect: async (action, listenerApi) => {

    const { requestStatus, requestId } = action.meta

    if (requestStatus === 'pending')
      requestRegister.addRequest(requestId)
    else
      requestRegister.removeRequest(requestId)
    
    listenerApi.dispatch(setAsyncState({ pending: !requestRegister.isEmpty(), error: false }))
  }
})


/**
 * Log a warning
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

    if (isRejected(action)) {
      console.error(action.type, action.error.message)
    }

    return next(action)
}

export const store = configureStore({
  reducer: {
    global: globalSlice,
    route: routeSlice,
    map: mapSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(busyListener.middleware).concat(rtkQueryErrorLogger)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
