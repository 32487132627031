import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectAsyncState } from './app/globalSlice'

const BusyIndicator = () => {

    const [show, setShow] = useState(false)
    const async = useSelector(selectAsyncState)

    const isPending = useCallback(() => {
        return async.pending
    }, [ async.pending ])

    useEffect(() => {

        const timeout = setTimeout(() => {
            setShow(isPending())
        }, 500)

        // useEffect caches the state so we must clear the previous timer if state changes while former is running
        return () => {
            clearTimeout(timeout)
        }
    }, [ async, isPending ])

    return (
        <Dialog open={show}>
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        </Dialog>
    )
}

export default BusyIndicator