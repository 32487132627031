import { Box, Button, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { hideInfoView, hideView, selectInfoContent, selectUiState, showInfoView, showView } from '../../app/globalSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import AppDialog, { AppBox, AppButton, AppStackDivider } from '../../components/AppComponents'
import { viewContent } from './StaticHTML'

const HamburgerMenu = ({ onClick }: { onClick: () => void }) =>
    <Button
        disableRipple
        onClick={onClick}
        sx={{
            backgroundColor: 'white',
            color: 'rgba(21, 14, 55, 0.85)',
            float: 'right',
            top: '15px',
            right: '15px',
            padding: '0.5rem',
            borderRadius: '35%',
            minWidth: 0,
            '&:hover': { backgroundColor: 'white' },
        }}>
            <span className='material-icons'>menu</span>
    </Button>

type SideMenuListItemProps = {
    name: string,
    icon: string,
    onClick?: () => void
}

const SideMenuListItem = ({ name, icon, onClick = () => {} }: SideMenuListItemProps) => (
    <div onClick={onClick}>
        <Typography
            sx={{
                fontSize: '16px',
                fontWeight: 400,
                color: '#333333'              
            }}
        ><span className='material-icons' style={{ color: '#6685FF' }}>{icon}</span> {name}</Typography>
    </div>
)

const LanguageSelection = () => {

    const { t, i18n } = useTranslation()

    return (
        <>
            <SideMenuListItem name={t('infoViews.language')} icon='language' />
            <Select value={i18n.language.substring(0 ,2)} onChange={(event: SelectChangeEvent) => i18n.changeLanguage(event.target.value)}>
                <MenuItem value={'en'}>English</MenuItem>
                <MenuItem value={'fi'}>suomi</MenuItem>
            </Select>
        </>
    )
}

export const SideInfoView = () => {

    const { t } = useTranslation()

    const { sideMenu: { visible } } = useAppSelector(selectUiState)
    const dispatch = useAppDispatch()

    return (
        <div className='side-info'>
        { 
            !visible ? <HamburgerMenu onClick={() => dispatch(showView('sideMenu'))} /> : (
                    <AppBox
                        sx={{
                            borderRadius: '25px 0 0 25px',
                            height: '100vh',
                            right: 0,
                            top: 0,
                        }}
                    >
                        <Stack><AppButton title={t('general.close')} icon='close' onClick={() => dispatch(hideView('sideMenu'))}  /></Stack>
                        <Stack divider={<AppStackDivider />}>
                            <LanguageSelection />
                            <SideMenuListItem name={t('infoViews.info')} icon='info' onClick={() => dispatch(showInfoView({ content: 'generalAppInformation' }))} />
                            <SideMenuListItem name={t('infoViews.help')} icon='help' onClick={() => dispatch(showInfoView({ content: 'helpText' }))} />
                        </Stack>
                    </AppBox>
            )
        }
        </div>
    )
}

export const GeneralAppInfoView = () => {

    const { infoView: { visible }} = useAppSelector(selectUiState)
    const { infoViewContent, infoViewTitle } = useAppSelector(selectInfoContent)
    
    const dispatch = useAppDispatch()

    const content = !!i18next.language ? DOMPurify.sanitize(viewContent(i18next.language)[infoViewContent]) : ''

    return (
        <AppDialog title={infoViewTitle} open={visible} onClose={() => dispatch(hideInfoView())}>
            <Box dangerouslySetInnerHTML={{ __html: content }}>
            </Box>
        </AppDialog>
    )
}
